import http from './http-common';
import { RootModel } from '../models/formSchema/root';

class FormTemplateService {
  get(id: string) {
    return http.get<RootModel>(`/FormTemplates/${id}`);
  }

  subjectless(id: string, tenantId: string) {
    return http.get<RootModel>(`/FormTemplates/subjectless/${tenantId}/${id}`);
  }

  put(id: string, body: RootModel) {
    return http.put<RootModel>(`/FormTemplates/${id}`, body);
  }

  post(body: RootModel) {
    return http.post<RootModel>('/FormTemplates', body);
  }

  delete(id: string, tenantId: string) {
    return http.delete<RootModel>(`/FormTemplates/${tenantId}/${id}`);
  }
}

export default new FormTemplateService();
