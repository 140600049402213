import { useForm } from 'react-hook-form';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import Button from '../elements/buttons/button';
import { lazy, useEffect, useRef, useState } from 'react';
import { QuestionModel } from '../../../models/formSchema/question';
import { useFormGenericStateContext } from '../../../context/form-context';
import { StepModel } from '../../../models/formSchema/step';
import { Link } from 'react-router-dom';
import { FormRequest } from '../../../models/forms/form-request';
import { UpdateFormRequest } from '../../../models/forms/update-form-request';
import { saveForm, updateForm } from '../../../slices/form/form-assessment-slice';
import { useAppDispatch } from '../../../hooks/useTypedSelector';
import { FormAssessmentResponse } from '../../../models/forms/form-assessment-response.model';
import { FormEntity } from '../../../models/forms/form-entity';
import RepeatableFieldComponent from '../elements/repeatable-fields/repeatable-fields';
import UseSwitch from '../../../hooks/use-switch';
import RadioButtonGroup from '../elements/radio-buttons/radioButtons/radioButtonGroup';
import useModal from '../../../hooks/use-modal';
import Modal from '../../modal/modal';
import ConfirmationModal from '../../modal/confirmation-modal';
import FileExistsModal from '../../modal/file-exists-modal';
import FileUploadService from '../../../services/fileUpload.service';
import { UploadFileAction } from '../../../Enums/UploadFileAction';
import { TenantModel } from '../../../models/tenants/tenant-model';
import { RootModel } from '../../../models/formSchema/root';
import { RadioButtonContext } from '../../../context/radio-button.context';

import './style.scss';
import { upload } from '../../../slices/form/file-upload-slice';
import { fileExists } from '../../../slices/form/file-exists-slice';
import LoadingSpinner from '../../loader/loader';
import { EntityModel } from '../../../models/formSchema/entity';

const Summary = lazy(() => import('./summary'));

export interface IProps {
  assessment: FormAssessmentResponse;
  schemaId: string;
  questions: Array<QuestionModel>;
  steps: Array<StepModel>;
  section: number;
  title: string;
  formTitle: string;
  tenant: TenantModel;
  sections: number;
  formData: RootModel;
}

const RenderForm = ({
  assessment,
  schemaId,
  questions,
  steps,
  section,
  title,
  formTitle,
  sections,
  formData,
  tenant,
}: IProps): JSX.Element => {
  const {
    step,
    setStep,
    summary,
    setSummary,
    conditionalStep,
    setConditionalStep,
    urn,
    setUrn,
    fileUploadObj,
    setFile,
    name,
    setFilePropName,
    setSubmitData,
  } = useFormGenericStateContext();
  const [previousCondition, setPreviousCondition] = useState(0);
  const dispatch = useAppDispatch();
  const {
    control,
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setError,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  let buttonsContainer;
  let formId = assessment?.formId;
  const [submitFormdata, setSubmitFormData] = useState(null);
  const [selectedRadioButton, setSelectedRadioButton] = useState<string>('No');
  const [repeatableCount, setRepeatableCount] = useState(1);
  const { isOpen, handleClose } = useModal();
  const [useConfirmationModal, setUseConfirmationModal] = useState<boolean>(true);
  const [loadingUploadRef, setLoadingUploadRef] = useState(false);
  const [loadingUpdateRef, setLoadingUpdateRef] = useState(false);
  const [fileUploadName, setFileUploadName] = useState<string>('');
  const [fileNameToDelete, setFileNameToDelete] = useState<string>('');

  let fileNameAfterUpload = '';
  const tenantName = tenant?.name;
  const text = 'This page contains unsaved changes.';

  const deleteFileOnSave = async () => {
    try {
      await FileUploadService.deleteFile(formId, fileNameToDelete);
    } catch (err) {
      console.error('Error:', err);
    } finally {
      setFileNameToDelete('');
    }
  };

  const onCancel = () => {
    handleClose();
    setUseConfirmationModal(true);
  };

  const isRepeatable =
    repeatableCount === 1
      ? steps[conditionalStep].repeatableTitle.replace(/\s+/g, '')
      : `${steps[conditionalStep].repeatableTitle.replace(
        /\s+/g,
        '',
      )}${repeatableCount.toString()}`;

  const options = [
    {
      text: 'Yes',
      value: 'Yes',
    },
    {
      text: 'No',
      value: 'No',
    },
  ];

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'Escape' && isOpen) {
      onCancel();
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    isOpen ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'unset');
    document.addEventListener('keydown', onKeyDown, false);
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setPreviousCondition(0);
    unregister();
  }, [setPreviousCondition, unregister, repeatableCount, isOpen]);

  const handleSaveForm = (formPost: FormRequest, postSaveAction?: () => void) => {
    const args = {
      formRequest: formPost,
    };

    dispatch(saveForm(args))
      .unwrap()
      .then((formResponse) => {
        if (!assessment) {
          assessment = formResponse;
          formId = assessment?.formId;
        }

        if (postSaveAction) {
          postSaveAction();
        }
      })
      .catch((err) => {
        console.error('Error:', err);
      });
  };

  const handleUpdateForm = (formId: string, updateFormRequest: UpdateFormRequest) => {
    const args = {
      formId: formId,
      updateformRequest: updateFormRequest,
    };
    setLoadingUpdateRef(true);
    dispatch(updateForm(args))
      .unwrap()
      .then(() => {
        setLoadingUpdateRef(false);
      })
      .catch((err) => {
        console.error('Error:', err);
        setLoadingUpdateRef(false);
      });
  };

  if (summary) {
    return (
      <Summary
        key={`${step}-summary-key`}
        title={formTitle}
        steps={steps}
        assessment={assessment}
        questions={questions}
        sectionTitle={title}
        section={step}
        sections={sections}
        formData={formData}
        tenant={tenant}
        loadingRef={loadingUpdateRef}
      />
    );
  }

  const getNextStep = (steps: StepModel[], data: any) => {
    let conditionIndex = 0;
    let sectionIndex = section + 1;

    if (selectedRadioButton === 'Yes') {
      setRepeatableCount((prev) => prev + 1);
      conditionIndex = conditionalStep;
      sectionIndex = section;
      setConditionalStep(conditionIndex);
      setPreviousCondition(conditionIndex - 1);
      setStep(sectionIndex);
    } else {
      const nextIndex = conditionalStep + 1;
      setRepeatableCount(1);
      if (nextIndex < steps.length) {
        for (let i = conditionalStep; i < steps.length; i++) {
          if (
            steps[i].skipToSummaryConditions != null &&
            steps[i].skipToSummaryConditions.every((x) => data[x.questionId] == x.value)
          ) {
            sectionIndex = section;
            setStep(sectionIndex);
            setSummary(true);
            return;
          }
        }

        for (let i = nextIndex; i < steps.length; i++) {
          if (
            steps[i].conditions == null ||
            steps[i].conditions.every((x) => data[x.questionId] == x.value)
          ) {
            conditionIndex = i;
            sectionIndex = section;
            break;
          }
        }
        setConditionalStep(conditionIndex);
        setPreviousCondition(conditionalStep);
        setStep(sectionIndex);
      }

      if (sectionIndex !== section) {
        sectionIndex = section;
        setStep(sectionIndex);
        setSummary(true);
      }
    }
  };

  const getPrevStep = (
    e: React.FormEvent<EventTarget>,
    section: number,
    conditionalStep: number,
    repeatableCount: number,
  ) => {
    e.preventDefault();
    unregister();
    if (section === step && conditionalStep === 0) {
      if (previousCondition > 0) {
        setConditionalStep(previousCondition);
      }
      setStep(section - 1);
    }
    if (repeatableCount > 1) {
      setRepeatableCount((prev) => prev - 1);
      setStep(section);
      setConditionalStep(conditionalStep);
    }
    if (section === step && conditionalStep > 0 && repeatableCount == 1) {
      setRepeatableCount(repeatableCount);
      setSelectedRadioButton('No');
      setStep(section);
      setConditionalStep(previousCondition);
      setPreviousCondition(previousCondition - 1);
    }
  };

  const buttonLabelUpdate = () => {
    const questionId = questions.find((q) => q.type == 'fileuploadfield')?.id;
    if (
      steps[conditionalStep].entities.find((e) => e.id == questionId) &&
      !assessment?.entityValues?.find((x) => x.id == questionId)?.value
    )
      return 'Upload and Continue';
    return 'Save and Continue';
  };

  const uploadFile = (
    data: any,
    uploadFileAction: UploadFileAction,
    postUploadAction?: () => void,
  ) => {
    setLoadingUploadRef(true);
    dispatch(upload({ formId, fileUploadObj, uploadFileAction }))
      .unwrap()
      .then((obj) => {
        setUrn(urn.concat({ id: name, value: obj.id }));
        fileNameAfterUpload = obj.fileName;
        if (postUploadAction != null) {
          postUploadAction();
        }
        setLoadingUploadRef(false);
        getNextStep(steps, data);
        setFile(null);
      })
      .catch((err) => {
        setLoadingUploadRef(false);
        setError(name, {
          type: 'filetype',
          message: 'The selected file could not be uploaded – try again',
        });
      });
  };

  const checkFileExists = () => {
    setLoadingUploadRef(true);
    let fileName = '';

    if (fileUploadObj) {
      const fileUploadObject = fileUploadObj as File;
      fileName = fileUploadObject.name;
      setFileUploadName(fileName);
    }

    return dispatch(fileExists({ formId, fileName }))
      .unwrap()
      .then((obj) => {
        setLoadingUploadRef(false);
        return obj.data;
      })
      .catch((err) => {
        setLoadingUploadRef(false);
        setError(name, {
          type: 'filetype',
          message: 'Unable to check if the file already exists – please try again',
        });
        throw err;
      });
  };

  const checkFileExistsPromise = (data: any) => {
    dispatch(checkFileExists).then((obj) => {
      setLoadingUploadRef(false);
      if (obj) {
        setUseConfirmationModal(false);
        setSubmitFormData(data);
        handleClose();
      } else {
        setUseConfirmationModal(true);
        uploadFile(data, UploadFileAction.Upload, () => submitFormData(data));
      }
    });
  };

  const onSubmit = (data: any) => {
    if (fileNameToDelete.length > 0) {
      deleteFileOnSave();
    }

    if (fileUploadObj && 'name' in fileUploadObj) {
      if (!formId) {
        const formPost: FormRequest = {
          schemaId: schemaId,
          entityValues: new Array<FormEntity>(),
        };

        handleSaveForm(formPost, () => {
          checkFileExistsPromise(data);
        });
      } else {
        checkFileExistsPromise(data);
      }
    } else {
      submitFormData(data);
    }
  };

  const submitFormData = (data: any) => {
    let result: FormEntity[] = new Array<FormEntity>();
    let filteredResult: FormEntity[] = new Array<FormEntity>();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    result = Object.keys(data).map((key) => ({
      id: key,
      value: data[key].toString().toLowerCase().includes('filelist')
        ? data[key].length > 0
          ? fileNameAfterUpload
            ? fileNameAfterUpload
            : data[key][0].name
          : assessment?.entityValues?.find((x) => x.id == key)?.value
            ? assessment?.entityValues?.find((x) => x.id == key)?.value
            : 'No file uploaded'
        : data[key].toString().trim(),
    }));
    fileNameAfterUpload = '';

    if (assessment) {
      result = assessment.entityValues.concat(result);

      const ids = result.map((k) => k.id);
      let filtered = result.filter(({ id }, idx) => !ids.includes(id, idx + 1));
      if (steps[conditionalStep + 1]?.repeatable) {
        const entity = steps[conditionalStep].entities.find((x) =>
          result.filter((r) => r.id == x.id),
        )!;
        if (entity && data[entity.id] !== undefined && data[entity.id].toString() == 'N') {
          const repeatableIds = steps[conditionalStep + 1].entities.map((x) => x.id);
          filtered = filtered.filter(
            (e) =>
              !repeatableIds.some((r) => e.id.startsWith(r) && e.id.replace(/\d+$/g, '') === r),
          );
        }
      }

      if (steps[conditionalStep]?.entities.find((e) => e.conditions)) {
        const entities = steps[conditionalStep].entities.filter((x) => x.conditions);

        entities &&
          entities.forEach((e) => {
            if (data[e.id] !== undefined) {
              if (
                data[e.id].toString() == 'false' ||
                e.conditions.every(
                  (x) => x.value != null && x.value.toString() != data[e.id].toString(),
                )
              ) {
                const conditionalIds = e.entities.map((e) => e.id);
                filtered = filtered.filter(
                  (e) =>
                    !conditionalIds.some(
                      (c) => e.id.startsWith(c) && e.id.replace(/\d+$/g, '') === c,
                    ),
                );
              }
            }
          });
      }

      if (steps[conditionalStep]?.repeatable) {
        const currentStepMatch = result?.pop()?.id.match(/\d+/g);
        const currentStep: any = currentStepMatch ? currentStepMatch[0] : '';
        const contextualTitles = Object.keys(data).map((key) => key.replace(/\d+/g, ''));
        const repeatableTitle = steps[conditionalStep].repeatableTitle.replace(/\s+/g, '');
        const entityIds = steps[conditionalStep].entities.map((entity) =>
          entity.id.replace(/\s+/g, ''),
        );

        if (Object.values(data).includes('No')) {
          const repeatableEntities = result.filter((r) => {
            return r.id == repeatableTitle || entityIds[0] || entityIds[1];
          });

          const filteredExamples = repeatableEntities.filter((entity) => {
            if (entity.id.includes('line')) {
              const item = entity.id.split('-')[0];
              const id = parseInt(item.replace(/^\D+/g, ''));
              return id > currentStep && contextualTitles.includes(entity.id.replace(/\d+/g, ''));
            } else {
              const id = parseInt(entity.id.replace(/^\D+/g, ''));
              return id > currentStep && contextualTitles.includes(entity.id.replace(/\d+/g, ''));
            }
          });

          filtered = filtered.filter((e) => !filteredExamples.includes(e));
        }
      }

      if (steps[conditionalStep]?.entities.find((e) => e.conditions)) {
        const entities = steps[conditionalStep].entities.filter((x) => x.conditions);

        entities &&
          entities.forEach((e) => {
            if (data[e.id] !== undefined) {
              if (
                data[e.id].toString() == 'false' ||
                e.conditions.every(
                  (x) => x.value != null && x.value.toString() != data[e.id].toString(),
                )
              ) {
                const conditionalIds = e.entities.map((e) => e.id);
                filtered = filtered.filter(
                  (e) =>
                    !conditionalIds.some(
                      (c) => e.id.startsWith(c) && e.id.replace(/\d+$/g, '') === c,
                    ),
                );
              }
            }
          });
      }

      if (
        steps[conditionalStep + 1]?.conditions &&
        steps[conditionalStep + 1]?.conditions.every((x) =>
          result.some(
            (r) =>
              r.id == x.questionId &&
              x.value != null &&
              data[r.id]?.toString() != x.value.toString(),
          ),
        )
      ) {
        const numberOfRuns =
          (steps[conditionalStep + 1]?.numberOfNestedConditionals as number) + 1 || 1;
        let conditionalIds: string[] = [];

        for (let i = 1; i <= numberOfRuns; i++) {
          conditionalIds = conditionalIds.concat(
            steps[conditionalStep + i].entities
              .filter((x) => x.id !== 'ownsPropOther')
              .map((x) => x.id),
          );
          steps[conditionalStep + i].entities.forEach((e) => {
            e.entities?.map((x) => {
              conditionalIds.push(x.id);
            });
          });
        }
        filtered = filtered.filter(
          (e) => !conditionalIds.some((r) => e.id.startsWith(r) && e.id.replace(/\d+$/g, '') === r),
        );
      }

      const currentStep = conditionalStep;
      if (
        steps[currentStep].skipToSummaryConditions != null &&
        steps[currentStep].skipToSummaryConditions.every((x) => data[x.questionId] == x.value)
      ) {
        const ignoreQuestionIds: string[] = [];
        const pushIds = (entity: EntityModel) => {
          ignoreQuestionIds.push(entity.id);
          entity.entities?.forEach(pushIds);
        };

        for (let i = currentStep + 1; i < steps.length; i++) {
          steps[i].entities?.forEach(pushIds);
        }

        const trailingChars = [
          '-line_1',
          '-line_2',
          '-line_3',
          '-town_or_city',
          '-county',
          '-postcode',
          'Day',
          'Month',
          'Year',
        ];

        filtered = filtered.filter((e) => {
          const idWithoutTrailingChars = trailingChars.reduce(
            (id, char) => id.replace(char, ''),
            e.id,
          );

          return !ignoreQuestionIds.some((r) => {
            return (
              (e.id.startsWith(r) && e.id.replace(/\d+$/g, '') === r) ||
              idWithoutTrailingChars === r
            );
          });
        });
      }
      filteredResult = filtered;
      
      filteredResult = filteredResult.filter((e) => e.value !== '');

      const updateFormPost: UpdateFormRequest = {
        entityValues: filtered,
      };

      handleUpdateForm(formId, updateFormPost);
    } else {
      const formPost: FormRequest = {
        schemaId: schemaId,
        entityValues: result,
      };

      filteredResult = result;
      handleSaveForm(formPost);
    }

    if (!isOpen && !loadingUploadRef) {
      getNextStep(steps, data);
    }
    
    const submissionDateTime = {  
      id: 'acgSubmissionDateTime',  
      value: new Date().toISOString()
    };

    const submissionDateTimeIndex = filteredResult.findIndex((x) => x.id === submissionDateTime.id);
    if (submissionDateTimeIndex !== -1) {
      filteredResult.splice(submissionDateTimeIndex, 1);  
    }  
    
    filteredResult.push(submissionDateTime); 

    updateLocalAssessmentData(filteredResult);
  };

  const updateLocalAssessmentData = (formData: FormEntity[]) => {
    if (!formData) {
      return;
    }

    if (!assessment || !assessment.entityValues) {
      assessment = {
        formId: formId,
        schemaId: schemaId,
        entityValues: formData,
        createdAt: new Date(),
      };
    }

    const clonedAssessment: FormAssessmentResponse = { ...assessment };
    const newEntityValues: FormEntity[] = [];

    formData.forEach((formValue: FormEntity) => {
      newEntityValues.push(formValue);
    });
    clonedAssessment.entityValues = newEntityValues;

    if (assessment.formId) {
      setSubmitData(clonedAssessment);
    } else {
      setSubmitData(null);
    }
  };

  const handleBackButton = () => {
    setFile(null);
  };

  const handleBackOverview = () => {
    setStep(-1);
    setConditionalStep(0);
    setFileNameToDelete('');
    setFile(null);
  };

  const handleUploadCopy = () => {
    uploadFile(submitFormdata, UploadFileAction.UploadCopy, () => {
      handleClose();
      setUseConfirmationModal(true);
      submitFormData(submitFormdata);
    });
  };

  const handleUploadAndReplace = () => {
    uploadFile(submitFormdata, UploadFileAction.UploadAndReplace, () => {
      handleClose();
      setUseConfirmationModal(true);
      submitFormData(submitFormdata);
    });
  };

  const showModal = () => {
    const currentValues = getValues();
    const fileUploadFields = Object.entries(currentValues).filter(
      ([key, value]) => value instanceof FileList,
    );
    const uploadedFieldsChanged =
      fileUploadFields && fileUploadFields.find((x) => x[1]?.length > 0) ? true : false;

    Object.keys(currentValues).forEach((key) => {
      if (currentValues[key] === undefined) {
        delete currentValues[key];
      }
    });

    const formEntities = Object.keys(currentValues).map((key) => ({
      id: key,
      value:
        currentValues[key]?.toString() === '' || currentValues[key]?.toString() === undefined
          ? ''
          : currentValues[key]?.toString(),
    }));

    const changedEntities = formEntities.filter(
      (x) =>
        ((x.value === 'false' &&
          assessment?.entityValues?.find((t) => t.id == x.id) &&
          x.value !== assessment?.entityValues?.find((t) => t.id == x.id)?.value) ||
          (x.value &&
            x.value !== 'false' &&
            x.value !== assessment?.entityValues?.find((t) => t.id == x.id)?.value) ||
          (!x.value &&
            assessment?.entityValues?.find((t) => t.id == x.id) &&
            x.value !== assessment?.entityValues?.find((t) => t.id == x.id)?.value)) &&
        !fileUploadFields.find((v) => v[0] == x.id),
    );

    if ((changedEntities && changedEntities.length > 0) || uploadedFieldsChanged) {
      handleClose();
    } else {
      handleBackOverview();
    }
  };

  if (conditionalStep > 0) {
    buttonsContainer = (
      <div className="govuk-grid-row govuk-!-margin-top-4">
        <div className="govuk-grid-column-one-half flex__text-align--left">
          <Button
            id="back-button"
            label="Back"
            icon={<IoIosArrowBack />}
            iconPosition="left"
            onClick={(e) => {
              handleBackButton();
              getPrevStep(e, section, conditionalStep, repeatableCount);
            }}
          />
        </div>
        <div className="govuk-grid-column-one-half flex__text-align--right">
          {loadingUpdateRef ? (
            <LoadingSpinner />
          ) : loadingUploadRef ? (
            <LoadingSpinner />
          ) : (
            <Button
              id="next-button"
              label={buttonLabelUpdate()}
              icon={<IoIosArrowForward />}
              iconPosition="right"
              type="submit"
            />
          )}
        </div>
      </div>
    );
  } else {
    buttonsContainer = (
      <div className="govuk-grid-row govuk-!-margin-top-8">
        <div className="govuk-grid-column-one-half flex__text-align--left">
          <Button
            id="back-to-overview-button"
            label="Back To Overview"
            icon={<IoIosArrowBack />}
            iconPosition="left"
            onClick={() => showModal()}
            type="button"
          />
        </div>
        <div className="flex__text-align--right">
          {loadingUpdateRef ? (
            <LoadingSpinner />
          ) : loadingUploadRef ? (
            <LoadingSpinner />
          ) : (
            <Button
              id="next-button"
              label={buttonLabelUpdate()}
              icon={<IoIosArrowForward />}
              iconPosition="right"
              type="submit"
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onCancel={onCancel}
        modalContent={
          useConfirmationModal ? (
            <ConfirmationModal
              onConfirm={handleBackOverview}
              message={text}
              assessment={assessment}
              formValues={getValues()}
              schemaId={schemaId}
              onSaveForm={(formEntities) => updateLocalAssessmentData(formEntities)}
              deleteFileOnSave={deleteFileOnSave}
              fileNameToDelete={fileNameToDelete}
            />
          ) : (
            <FileExistsModal
              onUploadCopy={handleUploadCopy}
              onUploadAndReplace={handleUploadAndReplace}
              message={`File "${fileUploadName}" already exists. Please select an appropriate action below.`}
            />
          )
        }
      />
      <Link
        to={`/${tenantName}/${window.location.pathname.split('/' + String(tenantName) + '/')[1]}`}
        onClick={showModal}
        className="govuk-back-link"
      >
        Back to Overview
      </Link>
      <form
        key={`section-${section}`}
        data-testid="form"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <h1 className="govuk-heading-m">{title}</h1>
        <div className="govuk-panel__body">
          <div
            className="govuk-form-group govuk-!-margin-top-6 govuk-!-margin-bottom-6"
            dangerouslySetInnerHTML={{ __html: steps[conditionalStep].info }}
          />
          <>
            {steps[conditionalStep]?.repeatable ? (
              <RepeatableFieldComponent
                key={`${steps[conditionalStep].repeatableTitle.replace(/\s+/g, '')}-Key`}
                formValue={assessment}
                questions={questions}
                steps={steps}
                setError={setError}
                register={register}
                errors={errors}
                clearErrors={clearErrors}
                conditionalStep={conditionalStep}
                index={repeatableCount}
                setFile={setFile}
                setFilePropName={setFilePropName}
                setValue={setValue}
                getValues={getValues}
              />
            ) : (
              steps[conditionalStep]?.entities.map((entity, idx) => {
                return questions
                  .filter(({ id }) => id === entity.id)
                  .map((field) => {
                    return UseSwitch(
                      field,
                      register,
                      idx,
                      errors,
                      setError,
                      assessment,
                      questions,
                      entity,
                      setValue,
                      getValues,
                      clearErrors,
                      setFile,
                      setFilePropName,
                      undefined,
                      control,
                      setFileNameToDelete,
                    );
                  });
              })
            )}
            {steps && steps[conditionalStep].repeatable && (
              <RadioButtonContext.Provider value={{ selectedRadioButton, setSelectedRadioButton }}>
                <RadioButtonGroup
                  key={`key-${repeatableCount}`}
                  register={register}
                  errors={errors}
                  type={'radio'}
                  validationSchema={{ required: true }}
                  labelHeading={steps[conditionalStep].repeatableTitle}
                  options={options}
                  entities={assessment.entityValues}
                  name={isRepeatable}
                  setError={setError}
                  properties={{}}
                />
              </RadioButtonContext.Provider>
            )}
          </>
        </div>
        {buttonsContainer}
      </form>
    </>
  );
};

export default RenderForm;
