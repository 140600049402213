import { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../components/loader/loader';
import DashboardLayout from '../../components/layout/dashboard/dashboard-layout';
import ProtectedRoute from '../../routes/protected-route';
import FormBuilder from '@formbuilder/ui/dist/FormBuilder/FormBuilder';
import { FormListModel } from '../../models/forms/form-list.model';
import { useAppDispatch } from '../../hooks/useTypedSelector';
import { useNavigate } from 'react-router';
import { getFormJSON } from '../../slices/form/form-lookup-slice';
import { baseSchema } from '../../helpers/helper-schema';
import FormManagementService from '../../services/form-management.service';
import FormTemplateService from '../../services/formTemplate.service';
import { RootModel } from '../../models/formSchema/root';
import { ToastContainer, toast } from 'react-toastify';
import './form-editor.scss';
import { useLocation } from 'react-router-dom';
import { getAllSchemas } from '../../slices/form/form-management-slice';

function FormEditor() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [formLookupLoading, setFormLookupLoading] = useState(false);
  const [formsJSON, setFormsJSON] = useState('');
  const [isNewForm, setIsNewForm] = useState(true);
  const schemaId = window.location.pathname.split('/')[4];
  const tenant = useSelector((state: any) => state.tenants.entities);
  const schemaList: FormListModel[] =
    useSelector((state: any) => state.forms.forms.schemaList) || [];
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page');
  const pageSize = 10;

  useEffect(() => {
    if (schemaList.length === 0 && tenant.id) {
      dispatch(getAllSchemas({ tenantId: tenant.id, pageSize, page: Number(page) }))
        .unwrap()
        .catch((err) => {
          console.error('Error', err);
          if (tenant.name) {
            const path = `/${tenant.name}/dashboard`;
            navigate(path);
          }
        });
    }
  }, [page, tenant.id]);

  useEffect(() => {
    if (schemaList.some((x: FormListModel) => x.schemaId === schemaId)) {
      setFormLookupLoading(true);
      dispatch(getFormJSON({ schemaId: schemaId }))
        .unwrap()
        .then((forms) => {
          const prettyJSON = JSON.stringify(forms, null, 2);
          setFormsJSON(prettyJSON);
        })
        .catch((err) => {
          console.error('Error', err);
          if (tenant.name) {
            const path = `/${tenant.name}/dashboard`;
            navigate(path);
          }
        })
        .finally(() => {
          setFormLookupLoading(false);
          setIsNewForm(false);
        });
    }
  }, [schemaId, schemaList]);

  const notifyError = () => {
    toast.error('An error occured when trying to save the form schema', {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const notifySuccess = () => {
    setTimeout(() => {
      toast.success('Schema was successfully saved', {
        position: toast.POSITION.TOP_CENTER,
      });
    }, 100);
  };

  const uploadSchema = async (json: string) => {
    const rootModel: RootModel = JSON.parse(json);

    const formListItem: FormListModel = {
      schemaId: schemaId,
      tenantId: tenant.id,
      schemaName: rootModel.form.name,
    };

    try {
      if (isNewForm) {
        await Promise.all([
          FormManagementService.post(formListItem),
          FormTemplateService.post(rootModel),
        ]);
      } else {
        await Promise.all([
          FormManagementService.put(schemaId, formListItem),
          FormTemplateService.put(schemaId, rootModel),
        ]);
      }
      navigate(`/${tenant.name}/form-management`);
      notifySuccess();
    } catch (error) {
      notifyError();
    }
  };

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <DashboardLayout>
        <ProtectedRoute>
          {formLookupLoading ? (
            <LoadingSpinner />
          ) : (
            <main
              className="govuk-main-wrapper main__container"
              id="form-builder-wrapper"
              role="main"
            >
              {formsJSON ? (
                <FormBuilder data={formsJSON} saveHandler={uploadSchema} />
              ) : (
                <FormBuilder
                  data={baseSchema(schemaId, 'Example Schema Name')}
                  saveHandler={uploadSchema}
                />
              )}
            </main>
          )}
        </ProtectedRoute>
      </DashboardLayout>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Suspense>
  );
}

export default FormEditor;
