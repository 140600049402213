import { FC, useState } from 'react';

interface FileUploadModalProps {
  allowedExtensions: string[];
  onClose: () => void;
  title: string;
}

const FileUploadModal: FC<FileUploadModalProps> = ({ allowedExtensions, onClose, title }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isFileValid, setIsFileValid] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileExtension = file.name.split('.').pop()?.toLowerCase();

      if (fileExtension && allowedExtensions.includes(fileExtension)) {
        setErrorMessage(null);
        setIsFileValid(true);
        setSelectedFile(file);
      } else {
        setErrorMessage(
          `Only the following file extensions are allowed: ${allowedExtensions.join(', ')}`,
        );
        setIsFileValid(false);
        setSelectedFile(null);
      }
    } else {
      setErrorMessage(null);
      setIsFileValid(false);
      setSelectedFile(null);
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      // Handle file upload logic here
      console.log('Uploading file:', selectedFile);

      setSelectedFile(null);
      setIsFileValid(false);
      setErrorMessage(null);
      onClose();
    }
  };

  return (
    <div className="govuk-width-container">
      <main className="govuk-main-wrapper govuk-!-padding-2">
        <div className="govuk-form-group govuk-!-margin-top-7">
          <fieldset className="govuk-fieldset">
            <legend className="govuk-fieldset__legend govuk-fieldset__legend--l">
              <h1 className="govuk-fieldset__heading">{title} </h1>
            </legend>
            <label className="govuk-label" htmlFor="file-upload-1">
              Upload a file
            </label>
            {errorMessage && (
              <p id="file-upload-1-error" className="govuk-error-message">
                <span className="govuk-visually-hidden">Error:</span> {errorMessage}
              </p>
            )}
            <input
              className="govuk-file-upload"
              id="file-upload-1"
              name="fileUpload1"
              type="file"
              onChange={handleFileChange}
            />
          </fieldset>
        </div>
        <div className="button-container govuk-!-margin-top-4">
          <button
            type="button"
            className="govuk-button govuk-!-margin-right-2"
            onClick={handleUpload}
            disabled={!isFileValid}
          >
            Upload
          </button>
        </div>
      </main>
    </div>
  );
};

export default FileUploadModal;
